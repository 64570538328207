import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import './Credentials.scss';

export default function Credentials() {

    const { t } = useTranslation();

    return(
        <div id='credentials'>
            <div data-aos="fade-up">
                <div className="section-caption">
                    <h2 className="custom-underline">
                        {t('credentials.title')}
                    </h2>
                </div>
                <div className='credentials-content-wrapper'>
                    <b>CIVIL CASES</b>
                    <ul>
                        <li>OS 956/2011  Plaintiff: Suit decreed with cost (Easement Act)</li>
                        <li>AS 3/2016 (Appellant/plaintiff): Appeal allowed (Easement Act)</li>
                        <li>OS 64/18 Defendant: Suit dismissed with cost (Declarations of Title)</li>
                        <li>OS 30/2014 Plaintiff: Suit decreed (Suit for specific performance)</li>
                    </ul>
                    <b>CRIMINAL CASES</b>
                    <ul>
                        <li>S.C 205/15: Accused no.5 Acquitted (S.392 of IPC)</li>
                        <li>S.C 122/17: Accused no.2 Acquitted (S.302 of IPC)</li>
                    </ul>
                    <b>FAMILY DISPUTES</b>
                    <ul>
                        <li>OP 929/2023 Petitioner Petition allowed</li>
                        <li>OP 2740/2023 Petitioner Petition allowed</li>
                        <li>MC 56/20 for respondent petition dismissed</li>
                        <li>OP 127/24 Petitioner Petition allowed</li>
                    </ul>
                    <b>LAND AQUISITION CASES</b>
                    <ul>
                        <li>LAR 238/2011 Petition allowed</li>
                        <li>LAR 140/2012 Petition allowed</li>
                        <li>LAA 357/2018 Appellant Appeal allowed</li>
                        <li>LA 229/2020 Appellant Appeal allowed</li>
                        <li>LAA 227/2020 Appellant Appeal allowed</li>
                        <li>LAA 1979/2020 Appellant Appeal allowed</li>
                        <li>LAA 237/20 Appellant Appeal allowed</li>
                        <li>LAA 76/2021 Appellant Appeal allowed</li>
                        <li>LAA35/2023 Appellant Appeal allowed</li>
                    </ul>
                    <b>RENT CONTROL COURT</b>
                    <ul>
                        <li>RCP 39/2018 Petitioner Petition allowed</li>
                        <li>RCP40/2018 Petitioner Petition allowed</li>
                    </ul>
                    <b>MOTOR ACCIDENT CLAIM CASES</b>
                    <ul>
                        <li>OP (MV) 1455/2006 Petitioner Petition allowed</li>
                        <li>OP (MV) 2827/2016 Petitioner Petition allowed</li>
                        <li>OP (MV) 2973/2018 Petitioner Petition allowed</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}